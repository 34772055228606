import React, { Suspense } from 'react';
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { ThemeProvider } from 'styled-components';
import { DefaultTheme } from './themes/default';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import Loader from './layouts/loader/Loader';
import { CookiesProvider } from 'react-cookie';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Suspense fallback={<Loader />}>
    <CookiesProvider>
      <BrowserRouter>
        <ThemeProvider theme={DefaultTheme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </CookiesProvider>
  </Suspense>
);
