import { lazy } from 'react';
import Loadable from '../components/ui-component/Loadable';
/****Layouts*****/
const FullLayout = lazy(() => import('../layouts/MinimalLayout/full-layout/FullLayout.js'));

/***** Pages ****/

const Starter = Loadable(lazy(() => import('../views/starter/Starter')));
const About = Loadable(lazy(() => import('../views/About')));
const Alerts = Loadable(lazy(() => import('../ui-kit/Alerts')));
const Badges = Loadable(lazy(() => import('../ui-kit/Badges')));
const Buttons = Loadable(lazy(() => import('../ui-kit/Buttons')));
const Cards = Loadable(lazy(() => import('../ui-kit/Cards')));
const Grid = Loadable(lazy(() => import('../ui-kit/Grid')));
const Tables = Loadable(lazy(() => import('../ui-kit/Tables')));
const Forms = Loadable(lazy(() => import('../ui-kit/Forms')));
const Breadcrumbs = Loadable(lazy(() => import('../ui-kit/Breadcrumbs')));
const Partners = Loadable(lazy(() => import('../views/partners/partners.container')));
const Clients = Loadable(lazy(() => import('../views/clients/clients.container')));
const Finance = Loadable(lazy(() => import('../views/finance/finance.container')));
const Requisites = Loadable(lazy(() => import('../views/requisites/requisites.container')));
const News = Loadable(lazy(() => import('../views/news/news.container')));
const Admin = Loadable(lazy(() => import('../views/admin/admin.container')));
const WidgetSetting = Loadable(lazy(() => import('../views/widget/widget.container')));
const Users = Loadable(lazy(() => import('../views/users/users.container')));
const ClientsAdm = Loadable(lazy(() => import('../views/adm-clients/adm-clients.container.js')));

/*****Routes******/

const ThemeRoutesFullLayout = {
  path: '/',
  element: <FullLayout />,
  children: [
    // { path: '/', element: <Navigate to='/starter' /> },
    { path: '/', element: <Starter /> },
    { path: '/partners', element: <Partners /> },
    { path: '/clients', element: <Clients /> },
    { path: '/finance', element: <Finance /> },
    { path: '/about', element: <About /> },
    { path: '/news', element: <News /> },
    { path: '/admin', element: <Admin /> },
    { path: '/widget', element: <WidgetSetting /> },
    { path: '/alerts', element: <Alerts /> },
    { path: '/badges', element: <Badges /> },
    { path: '/buttons', element: <Buttons /> },
    { path: '/cards', element: <Cards /> },
    { path: '/grid', element: <Grid /> },
    { path: '/table', element: <Tables /> },
    { path: '/forms', element: <Forms /> },
    { path: '/breadcrumbs', element: <Breadcrumbs /> },
    { path: '/requisites', element: <Requisites /> },
    { path: '/users', element: <Users /> },
    { path: '/all-users', element: <ClientsAdm /> },
  ],
};

export default ThemeRoutesFullLayout;
