import {Spinner} from 'reactstrap';

import * as Styled from './style';

const Loader = () => (
  <Styled.LoaderContainer>
    <Spinner color='primary' />
  </Styled.LoaderContainer>
);

export default Loader;
