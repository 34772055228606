export const DefaultTheme = {
  breakpoints: {
    small: '576px',
    medium: '768px',
    large: '992px',
    extraLarge: '1200px',
  },
  colorBlackForText: '#202224',
  colorTextMuted: 'rgba(32, 34, 36, 0.5)',
  colorWhite: '#ffffff',
  colorBackground: '#F5F6FA',
  colorCardBackground: '#ffffff',
  colorAccent: '#4880FF',
  colorHoverTable: '#f8f9fa',
  colorSuccess: '#4880FF',
  colorSuccessHover: '#5A91FF',
  colorSuccessActive: '#376BE0',
  colorTableHead: '#F1F4F9',
  padding: '16px',
  margin: '30px',
  paddingPage: '12px',
  paddingBlock: '24px',
  paddingBlockMobile: '12px',
  gap: '20px',
  borderRadius: '14px',
  boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
  sidebarWidth: '260px',
  sidebarColor: '#ffffff',
  transition: '0.2s ease-in',
  indent: '16px',
  shadow: '6px 6px 54px rgba(0, 0, 0, 0.1)',

  errorMain: '#e57373',
  warningDark: '#ffb74d',
  orangeMain: '#ffa726',
  successMain: '#81c784',
  successDark: '#388e3c',
};
