import { useCookies } from 'react-cookie';
import Routes from './routes';
import { useAsync } from 'react-use';
import { getMe } from './api/data';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const [cookies, , delCookie] = useCookies(['name', 'first_name', 'last_name', 'phone', 'token']);
  const navigate = useNavigate();

  useAsync(async () => {
    if (cookies.token && cookies.token !== 'null') {
      try {
        await getMe(cookies.token);
      } catch (error) {
        delCookie('name');
        delCookie('first_name');
        delCookie('last_name');
        delCookie('phone');
        delCookie('token');
        delCookie('tag');
        delCookie('token');
        delCookie('user_type');
        delCookie('widget_id');
        //window.location.href = '/login';
        navigate('/login');
      }
    }
  }, [cookies.token]);

  return (
    <div className='dark'>
      <Routes />
    </div>
  );
};

export default App;
