import React from 'react';
import {Spinner} from 'reactstrap';

import * as Styled from './style';

export const Loader = () => (
  <Styled.FallBackSpinner>
    <Styled.Loading >
      <Spinner color='primary' />
    </Styled.Loading>
  </Styled.FallBackSpinner>
);
export default Loader;
