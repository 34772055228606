import axios from 'axios';

// const API_BACKEND = process.env.NODE_ENV === 'development' ? 'http://localhost:5001/' : 'https://amo-widgets.herokuapp.com/';

// const API_BACKEND = 'http://localhost:5001/';

const API_BACKEND = 'https://amo-widgets.herokuapp.com/';

export const instance = axios.create({
  baseURL: API_BACKEND,
});
