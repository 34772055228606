import {instance} from './base';

export const getMe = async (token) => {
  return await instance.get(`r/me`, {
    headers: {authorization: token},
  });
};

export const updateMe = async (data) => {
  return await instance.post(`r/me`, data, {
    headers: {authorization: data.token},
  });
};

export const getCommonChartData = async (token) => {
  return await instance.get(`r/common-chart`, {
    headers: {authorization: token},
  });
};

export const getLeadsTodayData = async (token) => {
  return await instance.get(`r/leads-today`, {
    headers: {authorization: token},
  });
};

export const getLeadsData = async ({token, page}) => {
  return await instance.get(`r/leads-data?page=${page}`, {
    headers: {authorization: token},
  });
};

export const getLeadsDataAdm = async ({token, page}) => {
  return await instance.get(`r/leads-data-adm?page=${page}`, {
    headers: {authorization: token},
  });
};

export const getMyFeeds = async (token) => {
  return await instance.get(`r/my-feeds`, {
    headers: {authorization: token},
  });
};

export const getMyPartners = async (token) => {
  return await instance.get(`r/my-partners`, {
    headers: {authorization: token},
  });
};

export const addMyPartner = async (data) => {
  return await instance.post(`r/my-partners/add`, data, {
    headers: {authorization: data.token},
  });
};

export const addRequisites = async (data) => {
  try {
    const response = await instance.post('/r/add-requisites', data, {
      headers: {authorization: data.token},
    });
    return response;
  } catch (error) {
    console.error(
      'Error:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getRequisites = async (data) => {
  return await instance.get('/r/requisites/', {
    headers: {authorization: data.token},
  });
};

export const getUserRequisites = async ({token, user_id}) => {
  return await instance.get(`r/adm/user-requisites?user_id=${user_id}`, {
    headers: {authorization: token},
  });
};

export const getWithdrawalHistory = async (data) => {
  return await instance.get('/r/withdrawal-history', {
    headers: {authorization: data.token},
  });
};

export const addPayRequest = async (data, token) => {
  try {
    const response = await instance.post('/r/pay-requests', data, {
      headers: {authorization: token},
    });
    return response;
  } catch (error) {
    const errorMessage = error.response ? error.response.data : error.message;
    throw new Error(errorMessage);
  }
};

export const getRequest = async (data) => {
  return await instance.get('/r/pay-requests', {
    headers: {authorization: data.token},
  });
};

export const getBalance = async (data) => {
  try {
    const response = await instance.get('/r/user-balance', {
      headers: {authorization: data.token},
    });
    return response.data;
  } catch (error) {
    console.error(
      'Error fetching user balance:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getPotentialProfit = async (data) => {
  try {
    const response = await instance.get('r/user-potential-profit', {
      headers: {authorization: data.token},
    });
    return response.data;
  } catch (error) {
    console.error(
      'Error fetching user potential profit:',
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateWidgetImage = async (data, token, widgetId) => {
  return await instance.post(`r/update-img/${widgetId}`, data, {
    headers: {authorization: token, 'Content-Type': 'multipart/form-data'},
  });
};

export const updateWidgetImageAdm = async (data, token, widgetId) => {
  return await instance.post(`r/update-img-adm/${widgetId}`, data, {
    headers: {authorization: token, 'Content-Type': 'multipart/form-data'},
  });
};

export const getMyWidget = async (token, widget_id) => {
  return await instance.get(`r/widget/${widget_id}`, {
    headers: {authorization: token},
  });
};

export const getCommonWidget = async (token, widget_id) => {
  return await instance.get(`r/w/${widget_id}`, {
    headers: {authorization: token},
  });
};

export const checkPhoneNumber = async ({phone}) => {
  return await instance.get(`r/check-seances?phone=${phone}`);
};

export const checkPhoneInAgentsProgram = async ({phone}) => {
  return await instance.get(`r/lead/count?phone=${phone}`);
};

export const sendLeadToDB = async (data) => {
  return await instance.post(`webhook/mlm`, data);
};

export const getWidgetData = async (widget_id) => {
  return await instance.get(`r/w/${widget_id}`);
};

export const getUsersAdm = async (token) => {
  return await instance.get(`r/adm/users`, {
    headers: {authorization: token},
  });
};

export const getPayRequestsAdm = async ({token, types}) => {
  return await instance.get(`r/adm/requests?types=${types}`, {
    headers: {authorization: token},
  });
};

export const getRequestUserData = async ({token, user_id}) => {
  return await instance.get(`r/adm/user-data?user_id=${user_id}`, {
    headers: {authorization: token},
  });
};

export const getRequestUserLeadsInfo = async ({token, user_id}) => {
  return await instance.get(`r/adm/user-clients-info?user_id=${user_id}`, {
    headers: {authorization: token},
  });
};

export const getUserClientsAdm = async ({token, user_id, page}) => {
  return await instance.get(
    `r/adm/user-clients-data?user_id=${user_id}&page=${page}`,
    {
      headers: {authorization: token},
    }
  );
};

export const updateUserRequest=async ({type, comment, request_id, token}) => {
  return await instance.post(
    `r/adm/request/update`,
    {
      type,
      comment,
      request_id,
    },
    {
      headers: {authorization: token},
    }
  );
};

export const getClientComments = async ({token, parent_id}) => {
  return await instance.get(
    `r/comments?parent_type=client&parent_id=${parent_id}`,
    {
      headers: {authorization: token},
    }
  );
};

export const addClientComment = async ({token, comment, parent_id}) => {
  return await instance.post(
    `r/comments`,
    {
      comment: comment,
      parent_type: 'client',
      parent_id,
    },
    {
      headers: {authorization: token},
    }
  );
};

export const updateAvatar = async (image, token) => {
  return await instance.post(`r/update-avatar`, image, {
    headers: {authorization: token},
  });
};
