import { lazy } from 'react';

// project imports
import Loadable from '../components/ui-component/Loadable';
import MinimalLayout from '../layouts/MinimalLayout/index';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../views/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('../views/authentication/authentication3/Register3')));

//* OTHER PAGES
const NotFoundPage = Loadable(lazy(() => import('../views/not_found/not_found.view')));
const CommonWidget = Loadable(lazy(() => import('../views/common-widget/common-widget.container')));
const SuccessPage = Loadable(lazy(() => import('../views/statuses/success')));
const ErrorPage = Loadable(lazy(() => import('../views/statuses/error')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

/*****Routes******/

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: <AuthLogin3 />,
    },
    {
      path: '/register',
      element: <AuthRegister3 />,
    },
    {
      path: 'w/:id',
      element: <CommonWidget />,
    },
    {
      path: 'widget/sended',
      element: <SuccessPage />,
    },
    {
      path: 'widget/error',
      element: <ErrorPage />,
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
};

export default AuthenticationRoutes;
